<template>
    <section>
        <content-block title="Paths" no_background>
            <a
                slot="right"
                class="edit-toggle"
                :class="{selected: is_editing_paths}"
                @click="is_editing_paths = !is_editing_paths"
            >
                <svgicon name="settings" />
            </a>

            <el-alert
                v-if="!paths.length"
                description="No paths saved."
                :closable="false"
            />
            <project-paths-item
                v-for="item in paths"
                v-else
                :key="item.id"
                :show_editing="user_is_super_admin && is_editing_paths"
                :item="item"
            />
            <ProjectPathsItem__new v-if="is_editing_paths" />
        </content-block>

        <content-block title="Credentials" no_background>
            <a
                slot="right"
                class="edit-toggle"
                :class="{selected: is_editing_credentials}"
                @click="is_editing_credentials = !is_editing_credentials"
            >
                <svgicon name="settings" />
            </a>

            <el-alert
                v-if="!credentials.length"
                description="No credentials saved."
                :closable="false"
            />
            <project-credentials-item
                v-for="item in credentials"
                v-else
                :key="item.id"
                :show_editing="user_is_super_admin && is_editing_credentials"
                :item="item"
            />
            <ProjectCredentialsItem__new v-if="is_editing_credentials" />
        </content-block>

        <content-block title="Values" no_background>
            <a
                slot="right"
                class="edit-toggle"
                :class="{selected: is_editing_values}"
                @click="is_editing_values = !is_editing_values"
            >
                <svgicon name="settings" />
            </a>

            <el-alert
                v-if="!values.length"
                description="No values saved."
                :closable="false"
            />
            <project-values-item
                v-for="item in values"
                v-else
                :key="item.id"
                :show_editing="user_is_super_admin && is_editing_values"
                :item="item"
            />
            <ProjectValuesItem__new v-if="is_editing_values" />
        </content-block>

        <!-- MODULES -->

        <project-modules v-if="$store.getters.is_bitbucket_authed" />
    </section>
</template>

<script>
import ProjectPathsItem from '@/pages/projects/components/ProjectPathsItem';
import ProjectPathsItem__new from '@/pages/projects/components/ProjectPathsItem__new';
import ProjectCredentialsItem from '@/pages/projects/components/ProjectCredentialsItem';
import ProjectCredentialsItem__new from '@/pages/projects/components/ProjectCredentialsItem__new';
import ProjectValuesItem from '@/pages/projects/components/ProjectValuesItem';
import ProjectValuesItem__new from '@/pages/projects/components/ProjectValuesItem__new';

import ProjectModules from '@/components/overview/ProjectModules';
import ContentBlock from '@/components/blocks/ContentBlock';

import userMixin from '@/mixins/user.mixin';

export default {
    name: 'project-tab-utils',
    components: {
        ProjectPathsItem,
        ProjectPathsItem__new,
        ProjectCredentialsItem,
        ProjectCredentialsItem__new,
        ProjectValuesItem,
        ProjectValuesItem__new,
        ProjectModules,
        ContentBlock,
    },
    mixins: [userMixin],
    data() {
        return {
            is_editing_paths: false,
            is_editing_credentials: false,
            is_editing_values: false,
        };
    },
    computed: {
        project() {
            return this.$store.getters.projectWithId(
                this.$route.params.project_id
            );
        },
        paths() {
            return this.$store.getters
                .pathsForProjectWithId(this.$route.params.project_id)
                .slice(0)
                .sort((a, b) => {
                    if (a.title > b.title) return 1;
                    return -1;
                })
                .sort((a, b) => {
                    if (a.env === b.env) return 0;

                    if (a.env === '-') return -1;
                    if (a.env === 'local' && b.env !== '-') return -1;
                    if (a.env === 'staging' && !['-', 'local'].includes(b.env))
                        return -1;
                    return 1;
                });
        },
        credentials() {
            return this.$store.getters
                .credentialsForProjectWithId(this.$route.params.project_id)
                .slice(0)
                .sort((a, b) => {
                    if (a.title > b.title) return 1;
                    return -1;
                })
                .sort((a, b) => {
                    if (a.env === b.env) return 0;

                    if (a.env === '-') return -1;
                    if (a.env === 'local' && b.env !== '-') return -1;
                    if (a.env === 'staging' && !['-', 'local'].includes(b.env))
                        return -1;
                    return 1;
                });
        },
        values() {
            return this.$store.getters
                .valuesForProjectWithId(this.$route.params.project_id)
                .slice(0)
                .sort((a, b) => {
                    if (a.title > b.title) return 1;
                    return -1;
                })
                .sort((a, b) => {
                    if (a.env === b.env) return 0;

                    if (a.env === '-') return -1;
                    if (a.env === 'local' && b.env !== '-') return -1;
                    if (a.env === 'staging' && !['-', 'local'].includes(b.env))
                        return -1;
                    return 1;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-toggle {
    width: 45px;
    height: 20px;
    display: flex;
    margin: 0 0 0 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid white;
    box-sizing: border-box;
    float: right;

    &.selected {
        border: 2px solid $blue !important;
    }

    &:hover {
        border: 1px solid #dcdfe6;
    }

    svg {
        width: 30px;
        fill: $black;
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 992px) {
    .item-container {
        flex-direction: column;
        height: auto;
        align-items: flex-start;

        &:not(:last-of-type) {
            margin-bottom: 24px;
        }

        ::v-deep {
            > * {
                width: 100% !important;
                max-width: 100% !important;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            > label {
                order: -1;
                margin-left: 0;
            }

            > button {
                margin-left: 0;
            }
        }
    }
}
</style>
