<template>
    <el-button
        v-if="linkedObject"
        icon="el-icon-link"
        @click="handleClick"
        :class="{clickable}"
        size="small"
    >
        {{ title }}
    </el-button>
</template>

<script>
import {fireRef2id} from '@/filters';

export default {
    name: 'ref-link-button',
    components: {},
    props: {
        link_key: {
            type: String,
            required: true,
        },
        link_value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            linkedObject: null,
        };
    },
    computed: {
        title() {
            if (!this.linkedObject) return;

            switch (this.link_key) {
                case 'estimates':
                case 'estimate_group':
                    return `Estimate: ${this.linkedObject.title}`;
                case 'parent_component':
                    return `Parent: ${this.linkedObject.name}`;
                default:
                    return null;
            }
        },
        clickable() {
            // TODO expand to handle other link types
            return this.link_key === 'estimates' && this.linkedObject;
        },
    },
    async created() {
        switch (this.link_key) {
            case 'estimates':
                this.linkedObject = this.$store.getters.estimateWithId(
                    this.link_value
                );
                break;
            case 'estimate_group':
                const egSnapshot = await this.$fire.doc(this.link_value).get();
                const data = egSnapshot.data();
                const eSnapshot = await data.estimate.get();
                this.linkedObject = eSnapshot.data();
                break;
            case 'parent_component':
                this.linkedObject = this.$store.getters.componentWithId(
                    this.link_value
                );
                break;
            default:
                break;
        }
    },
    methods: {
        handleClick() {
            // TODO: handle linking to appropriate path for other link types
            switch (this.link_key) {
                case 'estimates':
                    this.$router.push({
                        name: 'project_detail_estimate+detail',
                        params: {
                            project_id: fireRef2id(this.linkedObject.project),
                            estimate_id: this.linkedObject.id,
                        },
                    });
                    break;
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
:not(.clickable) {
    pointer-events: none;
}
</style>
