var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects-detail"},[_c('actions-buttons',[_c('el-tabs',{on:{"tab-click":_vm.tabChanged},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"Overview","name":"_overview"}}),_c('el-tab-pane',{attrs:{"label":"Components","name":"_components"}}),(
                    _vm.$store.getters.is_feature_enabled(
                        _vm.SUPPORTED_FEATURES.PROJECT_ESTIMATES
                    )
                )?_c('el-tab-pane',{attrs:{"label":"Estimates","name":"_estimates"}}):_vm._e(),(
                    _vm.$store.getters.is_feature_enabled(
                        _vm.SUPPORTED_FEATURES.PROJECT_CHANGE_REQUESTS
                    )
                )?_c('el-tab-pane',{attrs:{"label":"CRs","name":"_changerequests"}}):_vm._e(),_c('el-tab-pane',{attrs:{"label":"Timeline","name":"_timeline"}}),(
                    _vm.$store.getters.is_feature_enabled(
                        _vm.SUPPORTED_FEATURES.PROJECT_UTILS
                    )
                )?_c('el-tab-pane',{attrs:{"label":"Utils","name":"_utils"}}):_vm._e(),_c('el-tab-pane',{attrs:{"label":"Documents","name":"_documents"}}),(_vm.user_is_admin)?_c('el-tab-pane',{attrs:{"label":"Settings","name":"_settings"}}):_vm._e()],1)],1),(_vm.project)?_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[(_vm.activePageKey === '_overview')?_c('ProjectTab_overview',{key:"overview"}):_vm._e(),(_vm.activePageKey === '_components')?_c('ProjectTab_components',{key:"components"}):(_vm.activePageKey === '_estimates')?_c('ProjectTab_estimates',{key:"estimates"}):(_vm.activePageKey === '_estimates+detail')?_c('ProjectTab_estimate',{key:"estimates+detail"}):(_vm.activePageKey === '_changerequests')?_c('ProjectTab_changerequests',{key:"changerequests"}):(_vm.activePageKey === '_changerequests+detail')?_c('ProjectTab_changerequest',{key:"changerequests+detail"}):(_vm.activePageKey === '_timeline')?_c('ProjectTab_timeline',{key:"timeline"}):(_vm.activePageKey === '_utils')?_c('ProjectTab_utils',{key:"utils"}):(_vm.activePageKey === '_documents')?_c('ProjectTab_documents',{key:"documents"}):(_vm.activePageKey === '_notes')?_c('ProjectTab_notes',{key:"notes"}):(_vm.activePageKey === '_settings')?_c('ProjectTab_settings',{key:"settings"}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }