var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{attrs:{"popper-class":"block-popover","placement":"top","title":"Edit Component","trigger":"click"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{class:[
            'block',
            `depth-${_vm.depth}`,
            _vm.cur_node.meta.status,
            _vm.is_relative ? 'relative' : null,
            _vm.cur_node.meta.type,
            _vm.has_time_after ? 'has-time-after' : null,
            _vm.has_time_before ? 'has-time-before' : null,
        ],style:(_vm.block_style),attrs:{"slot":"reference"},slot:"reference"},_vm._l((_vm.cur_node.overlays),function(overlay){return _c(overlay.chartComponent,{key:overlay.id,tag:"component",attrs:{"tree":_vm.tree,"cur_node":overlay,"day_width":_vm.day_width,"depth":_vm.depth + 1,"days":_vm.days,"is_relative":"","relative_start":_vm.startDate},on:{"update:node":(e) => _vm.$emit('update:node', e)}})}),1),_c('div',[_c('el-date-picker',{attrs:{"value":_vm.startDate,"type":"date","size":"mini","placeholder":"Start date","format":"dd/MM/yyyy"},on:{"input":(v) => (_vm.startDate = v)}}),_c('el-date-picker',{attrs:{"value":_vm.endDate,"type":"date","size":"mini","placeholder":"End date","picker-options":_vm.end_date_picker_options,"format":"dd/MM/yyyy"},on:{"input":(v) => (_vm.endDate = v)}})],1),_c('div',{staticStyle:{"text-align":"right","margin-top":"10px"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":_vm.handleCancel}},[_vm._v(" cancel ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleSave}},[_vm._v(" save ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }