import { render, staticRenderFns } from "./ProjectTeamUser.vue?vue&type=template&id=6c41f8b7&scoped=true"
import script from "./ProjectTeamUser.vue?vue&type=script&lang=js"
export * from "./ProjectTeamUser.vue?vue&type=script&lang=js"
import style0 from "./ProjectTeamUser.vue?vue&type=style&index=0&id=6c41f8b7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c41f8b7",
  null
  
)

export default component.exports