var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.component)?_c('div',{staticClass:"component-wrapper"},[_c('div',{staticClass:"component-container",class:_vm.component.status + '-background',on:{"click":_vm.handleClick}},[_c('div',{staticClass:"header"},[(_vm.is_component_active)?_c('time-indicator',{attrs:{"component":_vm.component}}):_vm._e(),_c('div',{staticClass:"title"},[(_vm.component.ref)?_c('span',{staticClass:"ref"},[_vm._v(" "+_vm._s(_vm.component.ref)+" ")]):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.component.name)+" ")])]),_c('el-popover',{attrs:{"placement":"bottom","trigger":"click","popper-class":"options-popover"},model:{value:(_vm.settingsVisible),callback:function ($$v) {_vm.settingsVisible=$$v},expression:"settingsVisible"}},[_c('a',{staticClass:"options-trigger visible-on-hover",attrs:{"slot":"reference"},on:{"click":function($event){$event.stopPropagation();}},slot:"reference"},[_c('svgicon',{attrs:{"name":"settings"}})],1),_c('ul',[_c('li',{on:{"click":function($event){return _vm.hidePopoverAndUpdateComponent(
                                'highlight',
                                _vm.component.highlight ? null : true
                            )}}},[_vm._v(" "+_vm._s(_vm.component.highlight ? 'Highlighted' : 'Highlight')+" ")]),_c('li',{staticClass:"header"},[_vm._v(" Change status: ")]),(!_vm.is_component_active)?_c('li',{on:{"click":function($event){return _vm.hidePopoverAndUpdateComponent(
                                'status',
                                _vm.COMPONENT_STATUS.ACTIVE
                            )}}},[_vm._v(" Active ")]):_vm._e(),(!_vm.is_component_standby)?_c('li',{on:{"click":function($event){return _vm.hidePopoverAndUpdateComponent(
                                'status',
                                _vm.COMPONENT_STATUS.STANDBY
                            )}}},[_vm._v(" Standby ")]):_vm._e(),(!_vm.is_component_completed)?_c('li',{on:{"click":function($event){return _vm.hidePopoverAndUpdateComponent(
                                'status',
                                _vm.COMPONENT_STATUS.COMPLETED
                            )}}},[_vm._v(" Completed ")]):_vm._e()])])],1),(_vm.is_component_active)?_c('div',{staticClass:"info-container"},[_c('time-spent',{staticClass:"span-raw",attrs:{"component":_vm.component}}),_c('el-progress',{attrs:{"percentage":_vm.time_left_progress,"show-text":false,"color":_vm.time_left_progress < 100 ? '#1989FA' : '#F56C6C'}})],1):_vm._e(),(!_vm.is_component_active)?_c('div',{staticClass:"info-container"},[_c('time-spent',{staticClass:"span-raw",attrs:{"component":_vm.component}}),_c('span',{staticClass:"tag"},[_vm._v(" "+_vm._s(_vm.component.status)+" ")])],1):_vm._e()]),_c('component-ref-links',{attrs:{"component":_vm.component}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }