var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-width":"160px","model":_vm.project}},[_c('content-block',{attrs:{"title":"Settings"}},[_c('el-form-item',{attrs:{"label":"Name"}},[_c('el-input',{on:{"change":function($event){return _vm.update('name')}},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})],1),_c('el-form-item',{attrs:{"label":"Reference"}},[_c('el-input',{attrs:{"name":"ref","maxlength":"3"},on:{"change":(v) => _vm.update('ref', v?.toUpperCase())},model:{value:(_vm.project.ref),callback:function ($$v) {_vm.$set(_vm.project, "ref", $$v)},expression:"project.ref"}})],1),_c('el-form-item',{attrs:{"label":"Description"}},[_c('el-input',{attrs:{"type":"textarea","rows":2,"autosize":"","placeholder":"Please input"},on:{"change":function($event){return _vm.update('description')}},model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}})],1),_c('hr'),_c('el-form-item',{attrs:{"label":"Status"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select"},on:{"change":function($event){return _vm.update('status')}},model:{value:(_vm.project.status),callback:function ($$v) {_vm.$set(_vm.project, "status", $$v)},expression:"project.status"}},_vm._l((_vm.status_options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(_vm.is_atlassian_authed)?_c('div',[_c('hr'),_c('el-form-item',{attrs:{"label":"Jira org","prop":"jira_org","rules":[
                    {
                        validator: _vm.handleValidateDomainPrefix,
                        trigger: 'blur',
                    },
                ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"Organisation name - <orgname>.atlassian.net - leave blank to disable"},on:{"change":_vm.updateJiraOrg},model:{value:(_vm.project.jira_org),callback:function ($$v) {_vm.$set(_vm.project, "jira_org", $$v)},expression:"project.jira_org"}},[_c('el-button',{attrs:{"slot":"append","icon":_vm.jira_projects === null
                                ? 'el-icon-loading'
                                : 'el-icon-refresh'},on:{"click":_vm.updateJiraOrg},slot:"append"})],1)],1),_c('el-form-item',{attrs:{"label":"Project"}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.jira_projects === null),expression:"jira_projects === null"}],staticStyle:{"width":"100%"},attrs:{"element-loading-spinner":"el-icon-loading","disabled":_vm.jira_projects === false,"placeholder":_vm.jiraProjectsPlaceholder,"clearable":""},on:{"change":function($event){return _vm.update('jira_project')}},model:{value:(_vm.project.jira_project),callback:function ($$v) {_vm.$set(_vm.project, "jira_project", $$v)},expression:"project.jira_project"}},_vm._l((_vm.jira_projects),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.name,"value":item.key}})}),1)],1),_c('hr'),_c('el-form-item',{attrs:{"label":"Confluence org","prop":"confluence_org","rules":[
                    {
                        validator: _vm.handleValidateDomainPrefix,
                        trigger: 'blur',
                    },
                ]}},[_c('el-input',{attrs:{"clearable":"","placeholder":"Organisation name - <orgname>.atlassian.net - leave blank to disable"},on:{"change":_vm.updateConfluenceOrg},model:{value:(_vm.project.confluence_org),callback:function ($$v) {_vm.$set(_vm.project, "confluence_org", $$v)},expression:"project.confluence_org"}},[_c('el-button',{attrs:{"slot":"append","icon":_vm.confluence_spaces === null
                                ? 'el-icon-loading'
                                : 'el-icon-refresh'},on:{"click":_vm.updateConfluenceOrg},slot:"append"})],1)],1),_c('el-form-item',{attrs:{"label":"Space"}},[_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.confluence_spaces === null),expression:"confluence_spaces === null"}],staticStyle:{"width":"100%"},attrs:{"element-loading-spinner":"el-icon-loading","disabled":_vm.confluence_spaces === false,"placeholder":"Select a space to enable Confluence integration","clearable":""},on:{"change":function($event){return _vm.update('confluence_space')}},model:{value:(_vm.project.confluence_space),callback:function ($$v) {_vm.$set(_vm.project, "confluence_space", $$v)},expression:"project.confluence_space"}},_vm._l((_vm.confluence_spaces),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.name,"value":item.key}})}),1)],1)],1):_vm._e()],1),(_vm.$store.getters.is_bitbucket_authed)?_c('content-block',{attrs:{"title":"Modules"}},[_c('ul',{staticClass:"modules"},[_vm._l((_vm.modules),function(module){return _c('module-block',{key:module.id,attrs:{"module":module},on:{"click":_vm.handleClickOnModule}})}),_c('div',{directives:[{name:"only-admin",rawName:"v-only-admin"}],staticClass:"new",on:{"click":_vm.handleClickOnNewModule}},[_c('svgicon',{staticClass:"icon",attrs:{"name":"plus-square"}})],1)],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }