var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-footer",class:{subset: _vm.subset, complete: _vm.is_estimate_locked}},[_c('el-row',{attrs:{"gutter":5,"type":"flex","justify":"end"}},[_c('el-col',{staticClass:"total-label",class:{'hidden-xs-only': !_vm.is_estimate_draft},attrs:{"span":_vm.desc_header_width}},[_vm._v(" Total ")]),(_vm.is_estimate_draft)?_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.daysOrHours(_vm.total_min))}})]):_vm._e(),(_vm.is_estimate_draft)?_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.daysOrHours(_vm.total_max))}})]):_vm._e(),_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.daysOrHours(_vm.total_qty))}})]),(!_vm.is_estimate_draft)?_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.daysOrHours(_vm.total_final_qty))}})]):_vm._e(),(_vm.estimate.unit === _vm.RATE_UNIT.DAY)?_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.hours2duration(
                        _vm.total_final_qty * _vm.estimate.hours_per_day
                    )
                )}})]):_vm._e(),(!_vm.is_estimate_draft)?_c('el-col',{staticClass:"right",attrs:{"span":_vm.cost_width}},[_c('span',{staticClass:"total-value"},[_vm._v(_vm._s(_vm._f("currency")(_vm.total_cost)))])]):_c('el-col',{staticClass:"right",attrs:{"span":2}})],1),(!_vm.is_estimate_draft && _vm.group !== null)?_c('el-row',{staticClass:"discount-row",attrs:{"gutter":5,"type":"flex","justify":"end"}},[(_vm.show_link_to_component)?_c('el-col',{attrs:{"span":16}},[_c('span',{staticClass:"done-in-retainer"},[_c('el-switch',{on:{"change":(e) => _vm.handleUpdateLinkedComponent(e)},model:{value:(_vm.link_to_component_enabled),callback:function ($$v) {_vm.link_to_component_enabled=$$v},expression:"link_to_component_enabled"}}),_vm._v(" Done in retainer ")],1),(
                    _vm.link_to_component_enabled &&
                    _vm.linkable_components.length > 1
                )?_c('el-select',{attrs:{"value":_vm.linked_retainer_component,"size":"small"},on:{"change":(c) => _vm.handleUpdateLinkedComponent(true, c)}},_vm._l((_vm.linkable_components),function(component){return _c('el-option',{key:component.id,attrs:{"label":component.name,"value":component}})}),1):_vm._e()],1):(_vm.show_linked_label)?_c('el-col',{attrs:{"span":16}},[_c('span',{staticClass:"done-in-retainer"},[_vm._v(" Done in retainer ")])]):_vm._e(),(_vm.show_discount)?[_c('el-col',{staticClass:"discount-label",attrs:{"span":3}},[_c('span',[_vm._v("Discount %")])]),_c('el-col',{staticClass:"center",attrs:{"span":_vm.num_width}},[_c('el-input-number',{ref:"input_discount",staticClass:"input-value",attrs:{"controls":false,"size":"small","placeholder":"0","min":0,"max":100,"disabled":_vm.is_estimate_locked || _vm.show_linked_label},on:{"change":_vm.handleUpdateDiscount,"focus":_vm.selectInput},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1)]:_vm._e(),_c('el-col',{staticClass:"right group-total",attrs:{"span":_vm.cost_width}},[_c('span',{staticClass:"total-value"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total_cost_discounted))+" ")])])],2):_vm._e(),(_vm.credit?.enabled && !_vm.is_estimate_draft)?_c('div',{directives:[{name:"only-super-admin",rawName:"v-only-super-admin"}],staticClass:"credit-section"},[_c('el-row',{staticClass:"credit-row",attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":21}},[_c('span',[_vm._v(" "+_vm._s(_vm.credit.label || 'Credit')+" ")])]),_c('el-col',{attrs:{"span":3}},[_c('span',{staticClass:"total-value"},[_vm._v(" "+_vm._s(_vm._f("currency")(-_vm.credit.value))+" ")])])],1),_c('el-row',{staticClass:"credit-row",attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":21}},[_c('span',[_vm._v("Total after credit")])]),_c('el-col',{attrs:{"span":3}},[_c('span',{staticClass:"total-value"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total_after_credit))+" ")])])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }