var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-container",class:{
        invalid: !_vm.render_excluded && _vm.is_invalid,
        'is-draft': _vm.is_estimate_draft,
        excluded: _vm.render_excluded,
        selected: _vm.selected && !_vm.show_subtasks,
        selectable: !_vm.is_estimate_draft && !_vm.show_subtasks,
    },on:{"click":function($event){return _vm.$emit('select', _vm.task.id)}}},[_c('div',{staticClass:"task-row"},[_c('el-form',{ref:"task_form",attrs:{"model":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":_vm.desc_width}},[_c('div',{staticClass:"row description"},[_c('div',{staticClass:"taskGrip"},[_vm._v("⣿")]),_c('svgicon',{staticClass:"jira-icon",class:_vm.ISSUE_ICONS[_vm.jira_category],attrs:{"name":_vm.ISSUE_ICONS[_vm.jira_category]}}),_c('el-input',{ref:"description",staticClass:"input-description",class:{
                                empty: _vm.isEmpty(_vm.form.description),
                            },attrs:{"size":"small","placeholder":"task description","readonly":_vm.readonly},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubmitDescription.apply(null, arguments)},"click":function($event){$event.stopPropagation();}},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}},[_c('el-tooltip',{attrs:{"slot":"suffix","placement":"top"},slot:"suffix"},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('strong',[_vm._v("Tasks")]),_vm._v(" define the main blocks of work within a group."),_c('br'),_c('br'),_vm._v(" Tasks become the description of the "),_c('strong',[_vm._v("Line Item")]),_vm._v(" in the quote,"),_c('br'),_vm._v(" and "),_c('strong',[_vm._v("Jira issues")]),_vm._v(" when the estimate is accepted. ")]),_c('span',{staticClass:"info-icon"},[_vm._v("?")])])],1)],1)]),_c('el-col',{attrs:{"span":_vm.num_width}},[_c('div',{staticClass:"row"},[(!_vm.has_subtasks && _vm.is_estimate_draft)?_c('el-input-number',{ref:"input_qty",staticClass:"input-value",attrs:{"controls":false,"size":"small","placeholder":"0","min":0},on:{"change":_vm.handleBlurQty,"focus":_vm.selectInput},model:{value:(_vm.form.qty),callback:function ($$v) {_vm.$set(_vm.form, "qty", $$v)},expression:"form.qty"}}):_c('span',{staticClass:"el-input__inner input-value read-only",class:{bold: _vm.is_estimate_draft}},[_vm._v(" "+_vm._s(_vm.formattedQty)+" ")])],1)]),(!_vm.is_estimate_draft)?_c('el-col',{attrs:{"span":_vm.num_width}},[_c('div',{staticClass:"row"},[(
                                !_vm.has_subtasks &&
                                _vm.is_estimate_pending &&
                                (_vm.show_subtasks || _vm.selected)
                            )?_c('el-input-number',{ref:"input_override_qty",staticClass:"input-value override-qty",attrs:{"precision":2,"controls":false,"size":"small","min":0,"placeholder":`${_vm.formattedQty}`},on:{"focus":_vm.selectInput},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.override.qty),callback:function ($$v) {_vm.$set(_vm.override, "qty", $$v)},expression:"override.qty"}}):_c('span',{staticClass:"el-input__inner input-value read-only"},[_vm._v(" "+_vm._s(_vm.formattedOverrideQty)+" ")])],1)]):_vm._e(),(_vm.estimate.unit === _vm.RATE_UNIT.DAY)?_c('el-col',{attrs:{"span":_vm.num_width}},[_c('div',{staticClass:"row"},[_c('el-input',{ref:"input_hours",staticClass:"input-value read-only",class:{bold: _vm.is_estimate_draft},attrs:{"value":_vm.$options.filters.hours2duration(_vm.hours),"size":"small"}})],1)]):_vm._e(),(!_vm.is_estimate_draft)?_c('el-col',{attrs:{"span":_vm.cost_width}},[_c('div',{staticClass:"row"},[_c('money',_vm._b({ref:"input_cost",staticClass:"el-input__inner bold cost read-only right",attrs:{"value":_vm.task.cost}},'money',_vm.money,false)),(!_vm.is_estimate_locked)?_c('div',{staticClass:"exclude-icon",class:{
                                excluded: _vm.is_excluded,
                            },on:{"click":function($event){$event.stopPropagation();return _vm.handleExclude.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-circle-close"})]):_vm._e()],1)]):_vm._e(),(_vm.is_estimate_draft && !_vm.readonly)?_c('el-col',{attrs:{"span":_vm.option_width}},[_c('div',{staticClass:"options-col"},[_c('div',{staticClass:"option-icon add",on:{"click":_vm.handleAddSubtask}},[_c('i',{staticClass:"el-icon-plus"})]),(_vm.is_dirty)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"option-icon spinner",attrs:{"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0)"}}):(_vm.has_nontrivial_subtasks)?_c('el-popconfirm',{staticClass:"delete-confirm",attrs:{"placement":"top","title":_vm.confirm_delete_text},on:{"confirm":_vm.handleDelete},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"option-icon delete"},[_c('i',{staticClass:"el-icon-close"})])]},proxy:true}],null,false,621420078)}):_c('div',{staticClass:"option-icon delete",class:{
                                disabled: !_vm.can_delete,
                            },on:{"click":_vm.handleDelete}},[_c('i',{staticClass:"el-icon-close"})])],1)]):_vm._e()],1)],1)],1),(
            _vm.is_estimate_draft || _vm.show_subtasks || (_vm.selected && _vm.has_subtasks)
        )?_c('div',{staticClass:"subtasks-container",class:{boxed: (_vm.show_subtasks || _vm.selected) && _vm.has_subtasks},on:{"click":function($event){$event.stopPropagation();}}},[_c('draggable',_vm._b({staticClass:"subtasks-dropzone",class:{
                itemSortable: !_vm.is_estimate_locked && !_vm.readonly,
                empty: !_vm.sortedSubtasks.length,
            },attrs:{"list":_vm.sortedSubtasks,"handle":".subtaskGrip","disabled":_vm.is_estimate_locked || _vm.readonly || _vm.show_subtasks,"group":"task","animation":100},on:{"end":_vm.updateSubtaskOrder,"add":_vm.handleChangeSubtaskParent}},'draggable',_vm.dragOptions,false),_vm._l((_vm.sortedSubtasks),function(subtask){return _c('Estimate__subtask',{key:subtask.id,ref:`subtask_${subtask.id}`,refInFor:true,attrs:{"estimate":_vm.estimate,"group":_vm.group,"task":_vm.task,"subtask":subtask,"can_delete":_vm.is_estimate_draft,"readonly":_vm.readonly,"adjustment":_vm.finalAdjustment},on:{"save":_vm.handleSaveSubtask,"delete":_vm.handleDeleteSubtask,"exclude":_vm.handleExcludeSubtask,"nextSubtask":_vm.handleNextSubtask}})}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }