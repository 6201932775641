<template>
    <div v-if="has_links" class="ref-links">
        <ref-link-button
            v-for="(link, index) in links"
            :key="`${link.key}_${index}`"
            :link_key="link.key"
            :link_value="link.value"
        />
    </div>
</template>

<script>
import RefLinkButton from '@/components/generic/RefLinkButton';

export default {
    name: 'component-ref-links',
    components: {RefLinkButton},
    props: {
        component: {
            type: Object,
            required: true,
        },
    },
    computed: {
        links() {
            const keys = Object.keys(this.component?.ref_links ?? {});
            const result = [];
            keys.forEach((key) => {
                const value = this.component.ref_links[key];
                if (Array.isArray(value)) {
                    // if this key is an array, add each index as its own link
                    this.component.ref_links[key].forEach((val, index) => {
                        result.push({key, value: val});
                    });
                } else {
                    result.push({key, value});
                }
            });
            return result;
        },
        has_links() {
            return this.links.length > 0;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.ref-links {
    margin-top: 5px;
}
</style>
