import { render, staticRenderFns } from "./ProjectEvents.vue?vue&type=template&id=72dfc257&scoped=true"
import script from "./ProjectEvents.vue?vue&type=script&lang=js"
export * from "./ProjectEvents.vue?vue&type=script&lang=js"
import style0 from "./ProjectEvents.vue?vue&type=style&index=0&id=72dfc257&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72dfc257",
  null
  
)

export default component.exports