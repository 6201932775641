var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{attrs:{"placement":"top","popper-class":"overlay-popover","title":`Edit ${_vm.cur_node.meta.type}`,"width":"200","trigger":"manual"},on:{"show":_vm.handleShow,"click":_vm.handleClick},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{class:[
            'overlay',
            `depth-${_vm.depth}`,
            _vm.cur_node.meta.status,
            _vm.is_relative ? 'relative' : '',
            _vm.cur_node.meta.type,
            _vm.visible ? 'modal-visible' : '',
        ],style:(_vm.block_style),attrs:{"slot":"reference"},on:{"click":_vm.handleClick},slot:"reference"},[_c('div',{staticClass:"label-wrapper"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.cur_node.label))])])]),_c('div',{staticClass:"modal"},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}}),_c('el-date-picker',{attrs:{"value":_vm.startDate,"type":"date","size":"mini","placeholder":"Start date","format":"dd/MM/yyyy"},on:{"input":(v) => (_vm.startDate = v)}}),_c('el-date-picker',{attrs:{"value":_vm.endDate,"type":"date","size":"mini","placeholder":"End date","picker-options":_vm.end_date_picker_options,"format":"dd/MM/yyyy"},on:{"input":(v) => (_vm.endDate = v)}})],1),_c('div',{staticStyle:{"text-align":"right","margin-top":"10px"}},[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":_vm.handleDelete}},[_vm._v(" delete ")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":_vm.handleCancel}},[_vm._v(" cancel ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleSave}},[_vm._v(" save ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }