import { render, staticRenderFns } from "./Estimate_task.vue?vue&type=template&id=24a4ad67&scoped=true"
import script from "./Estimate_task.vue?vue&type=script&lang=js"
export * from "./Estimate_task.vue?vue&type=script&lang=js"
import style0 from "./Estimate_task.vue?vue&type=style&index=0&id=24a4ad67&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a4ad67",
  null
  
)

export default component.exports